<template>

<div class="f-upload">

  <form ref="fileform" @submit.prevent="submit">
   <b-alert variant="success" v-if="feedback" show> {{feedback}}</b-alert>
        <input
          class="form-control"
          type="text"
          name="name"
          id="name"
          v-model="name"
          placeholder="Folder name"
        />

      <div class="form-action">
      <button class="btn btn-primary" type="submit" :disabled="loading">
         <b-spinner small v-if="loading"></b-spinner>
          Submit
        </button>
        <button class="btn btn-danger" @click.prevent="reset">
          Reset
        </button> </div>
    </form>

  </div>


</template>

<script>

export default {
  name: 'FileUpload',
  props: ['folderId', 'folderName'],
  data() {
    return {
      name: '',
      feedback: '',
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.name) {
        const payload = {
          name: this.name,
          parentId: this.folderId,
        };
        this.loading = true;
        this.$store.dispatch('file/addFolder', payload).then(() => {
          this.reset();
          this.feedback = 'Folder created successfully.';
          this.$emit('fileAdded');
          this.loading = false;
        });
      }
    },
    reset() {
      this.name = null;
    },

  },
};
</script>
<style scoped lang="stylus">
 .form-action
   margin-top:1.5rem
   button
    margin-right .3rem
</style>
